import React, { Component } from "react";
import Input from "@/Input";
import {
  userName_reg,
  password_reg,
  phone_reg,
  email_reg,
  email_reg_2
} from "$LIB/SportReg";
import { ReactSVG } from "@/ReactSVG";
import { fetchRequest } from "$LIB/SportRequest";
import { ApiPort } from "$LIB/SPORTAPI";
import HostConfig from "$LIB/Host.config";
import { Cookie, getUrlVars } from "$LIB/js/Helper";
import { getE2BBValue } from "$LIB/js/util";
import Toast from "@/Toast";
const { LocalHost, HostApi } = HostConfig.Config;

export default class TabRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostName: "string",
      user: "", //账戶
      name: "", //用戶名
      password: "", //密碼
      number: "", //電話
      email: "", //郵箱
      iconName: "close",
      inputType: "password",

      userError: "",
      passwordError: "",
      numberError: "",
      emailError: "",
      referer: "", // 推薦代碼,
      isAffDisabled: false,
      maxLength: "",
      minLength: "",
      prefixes: [],
    };
  }

  componentDidMount() {
    this.Checkdomin();
    this.getPreFixPhone();
  }

  Checkdomin = () => {
    //如有Affcode 註冊頁所顯示Aff  不能編輯及更改 (WEB,Mobile ,App)
    var vars = getUrlVars();
    if (Cookie.GetCookieKeyValue("CO_affiliate") != "") {
      this.setState({
        isAffDisabled: true,
        referer: Cookie.GetCookieKeyValue("CO_affiliate"),
      });
    } else if (sessionStorage.getItem("affCode")) {
      this.setState({
        isAffDisabled: true,
        referer: sessionStorage.getItem("affCode"),
      });
    } else {
      fetchRequest(
        ApiPort.Domaincheck +
          global.location.protocol +
          "//" +
          global.location.host +
          "&?",
        "GET"
      )
        .then((res) => {
          if (res.affiliateCode != "") {
            this.setState({
              isAffDisabled: true,
              referer: res.affiliateCode ? res.affiliateCode : "",
            });
          } else if (vars.aff && vars.aff !== "") {
            this.setState({
              isAffDisabled: true,
              referer: vars.aff,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  postRegist = () => {
    const { name, password, number, email, btnStatus } = this.state;

    if (!btnStatus) return;

    const postData = {
      HostName: window.location.origin,
      RegWebsite: 31,
      Language: "zh-cn",
      Mobile: "86-" + number.replace(/ /g, ""),
      UserName: name,
      MediaCode: Cookie.GetCookieKeyValue("CO_Media") || null,
      Referer:
        Cookie.GetCookieKeyValue("CO_Referer") ||
        sessionStorage.getItem("affCode") ||
        null, // 推荐码// 推荐码,
      affiliateCode: this.state.referer || "",
      Email: email,
      Password: password,
      BrandCode: "FUN88",
      blackBoxValue: getE2BBValue(),
      e2BlackBoxValue: getE2BBValue(),
    };

    const hide = Toast.loading("注册中...");
    fetchRequest(ApiPort.Register, "POST", postData)
      .then((data) => {
        hide();
        console.log(data);
        if (data.isSuccess == false) {
          if (data.result.Code == "MEM00026") {
            Toast.error("用户名已注册", 2);
            return;
          } else if (data.result.Code == "MEM00064") {
            Toast.error("发现禁止的措辞", 2);
            return;
          } else if (data.result.Code == "MEM00041") {
            Toast.error("邮箱地址已注册", 2);
            return;
          } else if (data.result.Code == "VAL00002") {
            Toast.error("电话号码格式无效，请您检查。", 2);
            return;
          } else {
            console.log(data.result.Message);
            Toast.error("发生错误, " + data.result.Message, 2);
          }
        } else if (data.isSuccess == true) {
          Toast.success("注册成功, 欢迎来到乐体育", 2);
          if (!localStorage.getItem("isFirstLogin")) {
            localStorage.setItem("isFirstLogin", 1);
          }
          if (!localStorage.getItem("isShowHelp")) {
            localStorage.setItem("isShowHelp", 1);
          }
          // NoGoHome = false;
          global.isRegiser = true;
          // global.loginObj = {};
          // global.loginObj.name = name;
          // global.loginObj.password = password;
          this.props.postLogin(name, password);
        }
      })
      .catch((err) => {
        hide();
        console.log(err);
      });
      Pushgtagdata(`Register`, 'Submit', `Register`);
  };

  changeBtnStatus = () => {
    const { name, password, number, email,
        userError,
        passwordError,
        numberError,
        emailError } = this.state
    if ((!number || !name || !password || !email) || (userError !== "" || passwordError !== "" || numberError !== "" || emailError !== "")) {
      this.setState({
        btnStatus: false,
      });
      return;
    }
    this.setState({
      btnStatus: true,
    });
  };

  changeIconName = () => {
    if (this.state.iconName === "open") {
      this.setState({
        iconName: "close",
        inputType: "password",
      });
    } else {
      this.setState({
        iconName: "open",
        inputType: "text",
      });
    }
  };

  // 验证手机号码
  getPreFixPhone = () => {
    fetchRequest(ApiPort.VerifyPhone, "GET", "").then((res) => {
      if (res) {
        this.setState({
          maxLength: res.maxLength,
          minLength: res.minLength,
          prefixes: res.prefixes,
        });
      }
    });
  };

  nameOnBlur = () => {
        const { name } = this.state;
        // 用戶名檢測;
        if (!userName_reg.test(name)) {
        this.setState({
            userError: "用户名长度必须至少有6个字符，不能超过14个字符，仅可使用字母 'A-Z', 'a-z' , 数字 '0-9'。",
        },()=>this.changeBtnStatus());
        return;
        } else {
        this.setState({
            userError: "",
        },()=>this.changeBtnStatus());
    }
  };

  pwdOnBlur = () => {
    const { password } = this.state;
    // 密碼檢測
    if (!password_reg.test(password)) {
      this.setState({
        passwordError:
          "密码必须包含6-20个字符，字符只限于使用字母和数字。（可以包含 ^＃$@ 中的特殊字符）。",
        },()=>this.changeBtnStatus());
        return;
    } else {
      this.setState({
        passwordError: "",
    },()=>this.changeBtnStatus());
}
  };

  phoneOnBlur = () => {
    const { number, maxLength, minLength, prefixes } = this.state;
    //電話號碼格式檢測
    let nuj = number.replace(/\s+/g, "");
    // 長度檢測
    console.log(nuj.length)
    console.log(maxLength)
    let nujLengthCheck = nuj.length > 12 || nuj.length < 9;
    // 前三碼檢測
    let nujFirstThreeCheck = prefixes.some((v) => v === nuj.substring(0, 3));

    if (nujLengthCheck) {
      this.setState({
        numberError: "有效手机号码必须为9-12位数",
    },()=>this.changeBtnStatus());
    return;
    } else if (!nujFirstThreeCheck) {
      this.setState({
        numberError: "电话号码格式无效",
    },()=>this.changeBtnStatus());
    return;
    } else {
      this.setState({
        numberError: "",
    },()=>this.changeBtnStatus());
}
  };

  mailOnBlur = () => {
    const { email } = this.state;
    // 郵箱檢測
    const mailPrefixLessThanThree = email.split("@")[0].length < 3;
    const mailLengthError = email.length > 30;
    if (!email_reg.test(email) || !email_reg_2.test(email) || mailPrefixLessThanThree || mailLengthError) {
        this.setState({
        emailError: "错误电邮格式。",
      },()=>this.changeBtnStatus());
      return;
    } else {
      this.setState({
        emailError: "",
    },()=>this.changeBtnStatus());
}
  };
  render() {
    const {
      name,
      password,
      number,
      email,
      referer,
      iconName,
      inputType,
      btnStatus,
      userError,
      passwordError,
      numberError,
      emailError,
      maxLength,
    } = this.state;
    return (
      <div className="reg__wrap">
        <div className="reg__main">
          <Input
            type="text"
            placeholder="用户名"
            prefix={<ReactSVG className="loginSVG" src="/svg/login/user.svg" />}
            value={name}
            maxLength={14}
            onBlur={this.nameOnBlur}
            onChange={(e) => {
              this.setState(
                {
                  name: e.target.value,
                },
                () => {
                  this.changeBtnStatus();
                }
              );
            }}
          />
          {userError !== "" && <div className="warn-text">{userError}</div>}
          <Input
            type="password"
            placeholder="密码"
            value={password}
            prefix={<ReactSVG className="loginSVG" src="/svg/login/lock.svg" />}
            onBlur={this.pwdOnBlur}
            type={inputType}
            maxLength={20}
            onChange={(e) => {
              this.setState(
                {
                  password: e.target.value,
                },
                () => {
                  this.changeBtnStatus();
                }
              );
            }}
          >
            <ReactSVG
              className={`loginSVG login__pwd__${iconName}`}
              src={`/svg/login/eyes-${iconName}.svg`}
              onClick={this.changeIconName}
            />
          </Input>
          {passwordError !== "" && (
            <div className="warn-text">{passwordError}</div>
          )}

          <Input
            type="phone"
            placeholder="联系电话"
            onBlur={this.phoneOnBlur}
            prefix={
              <ReactSVG className="loginSVG" src="/svg/login/phone.svg" />
            }
            value={number}
            maxLength={maxLength}
            prefixText="+ 86"
            onChange={(e) => {
              this.setState(
                {
                  number: e.target.value.replace(/[^\d]/g, ""),
                },
                () => {
                  this.changeBtnStatus();
                }
              );
            }}
          />
          {numberError !== "" && <div className="warn-text">{numberError}</div>}
          <Input
            type="text"
            placeholder="电子邮箱"
            onBlur={this.mailOnBlur}
            prefix={<ReactSVG className="loginSVG" src="/svg/login/mail.svg" />}
            value={email}
            onChange={(e) => {
              this.setState(
                {
                  email: e.target.value,
                },
                () => {
                  this.changeBtnStatus();
                }
              );
            }}
          />
          {emailError !== "" && <div className="warn-text">{emailError}</div>}
          <Input
            type="text"
            placeholder="推荐代码"
            prefix={
              <ReactSVG className="loginSVG" src="/svg/login/sCode.svg" />
            }
            value={referer}
            maxLength={16}
            disabled={this.state.isAffDisabled}
            onChange={(e) => {
              this.setState(
                {
                  referer: e.target.value,
                },
                () => {
                  this.changeBtnStatus();
                }
              );
            }}
          >
            <div className="not-required-text">(非必填)</div>
          </Input>

          <div className="reg__rule">
            点击“注册”，即确认您已年满21周岁，且理解并接受我们的
            <a
              href="https://www.fun8003.com/cn/help/policy-termsandcondition.htm"
              target="_blank"
              rel="noreferrer noopener"
            >
              条款
            </a>
            与
            <a
              href="https://www.fun8003.com/cn/help/policy-privacy.htm"
              target="_blank"
              rel="noreferrer noopener"
            >
              隐私政策
            </a>
          </div>

          <div className="login__button">
            <button
              className={`login__btn__submit ${
                !btnStatus ? "btn-disable" : ""
              }`}
              disabled={!btnStatus}
              onClick={this.postRegist}
            >
              注册
            </button>
          </div>
        </div>
      </div>
    );
  }
}
