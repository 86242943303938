import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { ReactSVG } from '@/ReactSVG';
import Layout from '@/Layout';
import TabLogin from '@/Login/TabLogin';
import TabRegister from '@/Login/TabRegistered';
import Toast from '@/Toast';
import Router from 'next/router';
import { fetchRequest } from '$LIB/SportRequest';
import { ApiPort } from '$LIB/SPORTAPI';
import { Cookie } from '$LIB/js/Helper';
import HostConfig from '$LIB/Host.config';
import { logout } from '$LIB/data/userinfo';
import { connect } from 'react-redux';
import { ACTION_UserInfo_getBalanceSB, ACTION_UserInfo_login, ACTION_UserInfo_getSelfExclusion } from '$LIB/redux/actions/UserInfoAction';
import Service from '@/Header/Service';
import {
	ACTION_MaintainStatus_NoTokenBTI,
	ACTION_MaintainStatus_NoTokenIM,
	ACTION_MaintainStatus_NoTokenSABA,
} from '../lib/redux/actions/MaintainStatusAction';
import { getMemberInfo } from '../lib/data/userinfo';
import { signalRConnect } from '$LIB/js/signalR';
import { ACTION_UserSetting_Update } from '../lib/redux/actions/UserSettingAction';
import ReactIMG from '@/ReactIMG';
import { setIsLogin, getE2BBValue } from '$LIB/js/util';
const { LocalHost, HostApi } = HostConfig.Config;

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key: '1'
		};

		//延遲加載，用於首屏加速
		this.VendorIM = null;
		this.VendorBTI = null;
		this.VendorSABA = null;
	}

	asyncLoadVendors = async () => {
		if (!this.VendorIM) {
			this.VendorIM = (await import('$LIB/vendor/im/VendorIM')).default;
		}
		if (!this.VendorSABA) {
			this.VendorSABA = (await import('$LIB/vendor/saba/VendorSABA')).default;
		}
		if (!this.VendorBTI) {
			this.VendorBTI = (await import('$LIB/vendor/bti/VendorBTI')).default;
		}

		return { VendorIM: this.VendorIM, VendorSABA: this.VendorSABA, VendorBTI: this.VendorBTI};
	}

	changeTabKey = (key) => {
		this.setState({
			key: key + ''
		});
	};

	postLogin = (name, pwd) => {
		let postData = {
			hostName: window.location.origin,
			grantType: 'password',
			clientId: 'Fun88.CN.App',
			clientSecret: 'FUNmuittenCN',
			scope: 'Mobile.Service offline_access',
			appId: 'net.GB2BC.FUN88',
			siteId: 31,
			username: name,
			password: pwd,
			e2: getE2BBValue()
		};

		this.asyncLoadVendors(); //偷跑

		const hide = Toast.loading('登录中,请稍候...');
		fetchRequest(ApiPort.Login, 'POST', postData)
			.then(async (data) => {
				if (data.accessToken) {
					localStorage.setItem('username', JSON.stringify(data.memberDetails.userName));

					localStorage.setItem('memberCode', JSON.stringify(data.memberDetails.memberCode));

					ApiPort.Token = data.accessToken.token_type + ' ' + data.accessToken.access_token; // 寫入用戶token  token要帶Bearer
					localStorage.setItem(
						'memberToken',
						JSON.stringify(data.accessToken.token_type + ' ' + data.accessToken.access_token)
					);
					localStorage.setItem('refreshToken', JSON.stringify(data.accessToken.refresh_token));
					//localStorage.setItem('memberInfo', JSON.stringify(data.memberDetails)); 格式和/api/Member拿到的不一樣，不使用
					localStorage.setItem('PreferWallet', data.memberDetails.preferWallet);
					localStorage.setItem('LoginOTP', data.memberDetails.loginOTP);
					localStorage.setItem('Revalidate', data.memberDetails.revalidate);

					Cookie.Create('Geet_status', '1', 1);
					setIsLogin();
					this.props.userInfo_login(data.memberDetails.userName); //redux 紀錄登入態
					await this.props.userInfo_getSelfExclusion(true); //redux 獲取自我限制配置

					//獲取用戶信息
					getMemberInfo(() => {}, true); //這個函式會寫入localstorage

					//獲取notification配置
					const getNotiPromise = this.getMemberNotificationSetting().catch((e) => console.log(e));

					await this.asyncLoadVendors();

					console.log('===done asyncLoadVendors');

					//IM登入
					const IMloginPromise = this.VendorIM.getTokenFromGateway()
						.then((token) => {
							this.props.maintainStatus_noTokenIM(false);
						})
						.catch((e) => {
							this.props.maintainStatus_noTokenIM(true);
							console.log('im login failed', e);
						});
					//BTI登入
					const BTIloginPromise = this.VendorBTI.getTokenFromGateway()
						.then((token) => {
							this.props.maintainStatus_noTokenBTI(false);
						})
						.catch((e) => {
							this.props.maintainStatus_noTokenBTI(true);
							console.log('bti login failed', e);
						});
					//SABA登入
					const SABAloginPromise = this.VendorSABA.getTokenFromGateway()
						// .then(token => {
						// 	this.props.maintainStatus_noTokenSABA(false);
						// })
						//沙巴特殊，不需要處理noToken 但還是要處理catch 避免獲取失敗 在登入頁卡住
						.catch((e) => {
						//  this.props.maintainStatus_noTokenSABA(true);
							console.log('saba login failed', e);
						});

					//等待異步查詢完成
					const results = await Promise.all([ getNotiPromise, IMloginPromise, BTIloginPromise, SABAloginPromise ]);

					this.getDomain();
					this.props.userInfo_getBalanceSB(true); //redux 獲取SB餘額

					hide();
					if (typeof _paq === 'object') {
						_paq.push([ 'setUserId', data.memberDetails.memberCode ]);
					}
					//　檢測國家
					if (!data.memberDetails.currency || (data.memberDetails.currency.toUpperCase() !== 'CNY')) {
						Toast.error('不支援的国家');
						logout(data.memberDetails.language, data.memberDetails.currency, data.memberDetails.userName);
						return;
					}

					// 檢測封鎖的玩家
					if (data.memberDetails.isGameLock) {
						Toast.error('封锁的帐户');
						logout(data.memberDetails.language, data.memberDetails.currency, data.memberDetails.userName);
						return;
					}

					//signalR重連bind當前用戶
					signalRConnect(true);

					// 檢測OTP
					const mini = localStorage.getItem('miniurlopen'); //跳活動頁

					if (data.memberDetails.revalidate) {
						Router.push('/verify/?type=resetpwd');
					} else if (data.memberDetails.loginOTP) {
						Router.push('/verify/?type=loginOTP');
					} else if (mini !== null &&  mini != 'undefined' && mini == 'true')  {
						Router.push('/laborday');
					}else{
						const fromurl = localStorage.getItem('fromurl');
						if (fromurl && fromurl.length > 0) {
							localStorage.removeItem('fromurl'); //清除
							window.location.href = fromurl;
						} else {
							Router.push('/');
						}
					}

					window.RefreshTokensetInterval = setInterval(() => {
						this.RefreshToken();
					}, 3300000);
				} else {
					hide();
				}

				global.isRegiser = false;
				global.loginObj = {};
			})
			.catch((error) => {
				hide();
				console.log('登录失败:', error);
			});
	};

	getDomain = () => {
		fetchRequest(ApiPort.getMainsiteDomain, 'GET')
			.then((res) => {
				localStorage.setItem('domains', JSON.stringify(res));
			})
			.catch((error) => {
				// console.log(error);
			});
	};

	RefreshToken = () => {
		console.log('RefreshToken');
		var rstoken = JSON.parse(localStorage.getItem('refreshToken'));
		const postData = {
			grantType: 'refresh_token',
			clientId: 'Fun88.CN.App',
			clientSecret: 'FUNmuittenCN',
			refreshToken: rstoken
		};

		fetchRequest(ApiPort.RefreshTokenapi, 'POST', postData)
			.then((res) => {
				if (res) {
					if (res.access_token && res.refresh_token) {
						localStorage.setItem('memberToken', JSON.stringify('Bearer ' + res.access_token));
						ApiPort.Token = 'Bearer ' + res.access_token;
						localStorage.setItem('refreshToken', JSON.stringify(res.refresh_token));
					} else {
						Toast.error('请重新登录,访问过期', 3);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	getMemberNotificationSetting() {
		let that = this;
		return new Promise((resolve, reject) => {
			fetchRequest(ApiPort.GetMemberNotificationSetting, 'GET')
				.then((res) => {
					if (res && res.memberCode && res.notificationSetting) {
						//緩存
						localStorage.setItem(
							'NotificationSetting-' + res.memberCode,
							JSON.stringify(res.notificationSetting)
						);

						//加載 盤口展示方式
						const savedListDisplayType = res.notificationSetting['listDisplayType'];
						let listDisplayType = 1;
						if (parseInt(savedListDisplayType) === 2) {
							listDisplayType = 2;
						}
						that.props.userSetting_updateListDisplayType(listDisplayType);

						resolve(res.notificationSetting);
					} else {
						reject('GetMemberNotificationSetting no data??' + JSON.stringify(res));
					}
				})
				.catch((e) => reject('GetMemberNotificationSetting failed' + JSON.stringify(e)));
		});
	}

	render() {
		const { key } = this.state;
		return (
			<Layout status={0}>
				<div className="login__wrap">
					<ReactSVG
						className="back-icon"
						src="/svg/LeftArrow.svg"
						onClick={() => {
							Router.back();
						}}
					/>
					<div
						className="login-cs-icon"
						onClick={() => {
							Pushgtagdata(`LiveChat`, 'Launch', `LoginPage`);
						}}
					>
						<Service />
					</div>
					<ReactIMG className="login__header__img" src="/img/sports-app-registration-page2.webp" />

					<div className="login__main">
						<Tabs
							// className="tabs"
							prefixCls="tabsOvalLogin"
							defaultActiveKey={key}
							onChange={this.changeTabKey}
						>
							<TabPane tab="登入" key="1">
								<TabLogin postLogin={this.postLogin} />
							</TabPane>
							<TabPane tab="注册" key="2">
								<TabRegister postLogin={this.postLogin} />
							</TabPane>
						</Tabs>
					</div>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({
	userInfo: state.userInfo
});

const mapDispatchToProps = {
	userInfo_login: (username) => ACTION_UserInfo_login(username),
	userInfo_getBalanceSB: (forceUpdate = false) => ACTION_UserInfo_getBalanceSB(forceUpdate),
	userInfo_getSelfExclusion: (forceUpdate = false) => ACTION_UserInfo_getSelfExclusion(forceUpdate),
	maintainStatus_noTokenBTI: (isNoToken) => ACTION_MaintainStatus_NoTokenBTI(isNoToken),
	maintainStatus_noTokenIM: (isNoToken) => ACTION_MaintainStatus_NoTokenIM(isNoToken),
	maintainStatus_noTokenSABA: (isNoToken) => ACTION_MaintainStatus_NoTokenSABA(isNoToken),
	userSetting_updateListDisplayType: (currentType) =>	ACTION_UserSetting_Update({ListDisplayType: currentType}),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
