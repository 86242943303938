/*
 * @Author: Alan
 * @Date: 2021-11-08 14:02:33
 * @LastEditors: Alan
 * @LastEditTime: 2022-01-18 16:11:34
 * @Description: 正则
 * @FilePath: \Fun88-Sport-Code2.0\lib\SportReg.js
 */

// 公用正则表达式

export const password_reg = /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\^#$@]*)([\^#$@]*[a-zA-Z0-9][\^#$@]*)+$/;
export const userName_reg = /^[a-zA-Z0-9]{6,14}$/;
export const phone_reg = /^(0|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0135678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/;
export const email_reg = /^[A-Za-z0-9_]+(?:\.[a-zA-Z0-9_\.\-]){0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const email_reg_2 = /^[^_.-].*[^/_.-]$/; // 不能以_.-開頭結尾

// 用户真实姓名
export const realyNameReg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,15}$/;
// 充值账号后6位
export const sixLastNumReg = /^[0-9]{6}$/;
// 充值金额限制小数后两位
export const depositMoneyDecimal = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
// 充值金额限制整数
export const depositMoneyInt = /^[0-9]*$/;
//身份证  18位
export const idCard = /[0-9]{17}[0-9xX]$/;
//银行卡号  14-19位
export const BankCard =  /^[0-9]{14,19}$/;
//用户名称
export const bankNameReg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{1,}$/;

// 15-18位 /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
// /^[1-9][0-9]{5}(19[0-9]{2}|200[0-9]|2010)(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[0-9]{3}[0-9xX]$/
