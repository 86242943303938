import React, { Component, Fragment } from 'react';
import Input from '@/Input';
import { userName_reg, password_reg } from '$LIB/SportReg';
import { ReactSVG } from '@/ReactSVG';
import Router from 'next/router';
import { withBetterRouter } from '$LIB/js/withBetterRouter';
import ReactIMG from '@/ReactIMG';
import { checkIsLogin } from '$LIB/js/util';

class TabLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			password: '',
			loginNameStatus: '',
			loginPwdStatus: '',
			btnStatus: false,
			loginError: '',
			errorMessage: '',
			iconName: 'close',
			inputType: 'password'
		};
	}

	componentDidMount() {
		//歐洲杯跳轉處理，保存到localstorage
		const {query} = this.props.router;
		const from = query.from;
		if (from && from.toLowerCase() === 'ec2021') {
			localStorage.setItem('fromurl', '/ec2021');
		} else if (from && from.toLowerCase() === 'event_anniversary') {
			localStorage.setItem('fromurl', '/event_Anniversary');
		} else {
			localStorage.removeItem('fromurl'); //清除
		}

		if (checkIsLogin()) {
			const fromurl = localStorage.getItem('fromurl');
			if (fromurl && fromurl.length > 0) {
				localStorage.removeItem('fromurl'); //清除
				window.location.href = fromurl;
			} else {
				Router.push('/');
			}
		}
	}

	//登录
	login = () => {
		const { name, password, btnStatus } = this.state;
		if (name.trim().length === 0 || password.trim().length === 0) {
			this.setState({ loginError: '请输入您的用户名和密码。' });
			return;
		}

		if (!btnStatus) return;
		this.props.postLogin(name, password);
		Pushgtagdata(`Login`, 'Submit', `Login`);
	};

	changeIconName = () => {
		if (this.state.iconName === 'open') {
			this.setState({
				iconName: 'close',
				inputType: 'password'
			});
		} else {
			this.setState({
				iconName: 'open',
				inputType: 'text'
			});
		}
	};

	changeBtnStatus = () => {
		const { name, password, loginError } = this.state;

		if (!name || !password || loginError !== '') {
			this.setState({
				btnStatus: false
			});
			return;
		}
		this.setState({
			btnStatus: true
		});
	};

	onChangeName = (e) => {
		this.setState(
			{
				name: e.target.value
			},
			() => {
				this.checkInput('name');
			}
		);
	};

	onChangePwd = (e) => {
		console.log(e.target.value);
		this.setState(
			{
				password: e.target.value
			},
			() => {
				this.checkInput('password');
			}
		);
	};

	checkInput = (type) => {
		const { name, password, loginError } = this.state;

		if (type === 'name') {
			if (password.trim().length !== 0) {
				this.setState({ loginError: '' }, () => {
					this.changeBtnStatus();
				});
			}
			if (name.trim().length === 0) {
				this.setState({ loginError: '请输入您的用户名和密码。' }, () => {
					this.changeBtnStatus();
				});
			}
		}

		if (type === 'password') {
			if (name.trim().length !== 0) {
				this.setState({ loginError: '' }, () => {
					this.changeBtnStatus();
				});
			}
			if (password.trim().length === 0) {
				this.setState({ loginError: '请输入您的用户名和密码。' }, () => {
					this.changeBtnStatus();
				});
			}
		}
	};

	OnBlur = () => {
		const { name, password } = this.state;
		if (name.trim().length === 0 || password.trim().length === 0) {
			this.setState({ loginError: '请输入您的用户名和密码。' }, () => this.changeBtnStatus());
		} else {
			this.setState({ loginError: '' }, () => this.changeBtnStatus());
		}
	};

	render() {
		const { name, password, btnStatus, loginError, iconName } = this.state;
		return (
			<Fragment>
				{loginError !== '' && <div className="login__error">{loginError}</div>}

				<Input
					type="text"
					placeholder="用户名"
					prefix={<ReactSVG className="loginSVG" src={`/svg/login/user.svg`} />}
					value={name}
					onChange={this.onChangeName}
					maxLength={14}
					onBlur={this.OnBlur}
					onFocus={this.OnBlur}
				/>
				<Input
					type="password"
					placeholder="密码"
					value={password}
					prefix={<ReactSVG className="loginSVG" src={`/svg/login/lock.svg`} />}
					suffix={
						<ReactSVG
							className={`loginSVG login__pwd__${iconName}`}
							src={`/svg/login/eyes-${iconName}.svg`}
							onClick={this.changeIconName}
						/>
					}
					type={this.state.inputType}
					onChange={this.onChangePwd}
					maxLength={20}
					onBlur={this.OnBlur}
					onFocus={this.OnBlur}
				/>
				<div className="login__button">
					<button
						className={`login__btn__submit ${!btnStatus ? 'btn-disable' : ''}`}
						disabled={!btnStatus}
						onClick={this.login}
					>
						登录
					</button>
				</div>

				<div className="login__forget">
					<div className="login__forget__button" onClick={() => Router.push('/forgetpwd')}>忘记用户名或密码？</div>
				</div>
				<div
					onClick={() =>
						Router.push({
							pathname: '/verify',
							query: { type: 'resetpassword' }
						})}
				/>
				<div className="login__footer">
					<div className="login__footer__box">
						<ReactIMG src="/img/NUFC@2x.webp" />
						<div className="login__footer__text">
							<p>官方球衣赞助商</p>
							<p style={{ marginTop: '5px' }}>纽卡斯尔联足球俱乐部</p>
						</div>
					</div>
					<div className="login__footer__box">
						<ReactIMG src="/img/unnamed@2x.webp" />
						<div className="login__footer__text">
							<p>亚洲官方投注伙伴</p>
							<p style={{ marginTop: '5px' }}>托特纳姆热刺足球俱乐部</p>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withBetterRouter(TabLogin)
